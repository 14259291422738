<template>
  <section class="section--padding policy">
    <v-container>
      <div v-html="text"></div>

<!--      <h1 class="text-center mb-2">Политика обработки персональных данных</h1>

      <h2 class="h4 my-8">1. Общие положения</h2>
      <p>1.1. Настоящая Политика обработки персональных данных (далее - Политика) регулирует
        правоотношения по обработке персональных данных между пользователем сети "Интернет"
        (далее - Клиент, Вы) и Компанией (Мы).</p>
      <p>1.2. В этой Политике Вы найдете всю необходимую информацию об использовании нами
        персональных данных наших пользователей, покупателей и клиентов, независимо от канала,
        сервиса или средств передачи данных (в сети Интернет или лично), которые Вы используете для
        взаимодействия с нами.</p>
      <p>1.3. Мы придерживаемся принципа прозрачности в отношении любых операций, совершаемых
        с Вашими персональными данными, чтобы Вы в полной мере понимали, как, зачем и почему мы их
        обрабатываем, а также какими правами Вы обладаете в отношении Ваших данных. Для этого:</p>
      <ol class="ul-letters my-4">
        <li>
          <p>Мы предоставляем Вам постоянный доступ к настоящей Политике, с которой Вы можете
            ознакомиться в любое время.</p>
        </li>
        <li>
          <p>Вы также можете получить более подробную информацию о том, как мы обрабатываем
            ваши персональные данные посредством взаимодействия с нами по Телефону либо по
            Электронной почте.</p>
        </li>
      </ol>
      <p>1.4. Вы должны полностью ознакомиться с настоящей Политикой до начала использования
        Сайта.</p>
      <p>1.5. В случае несогласия с положениями Политики Вам следует воздержаться от дальнейшего
        использования Сайта.</p>
      <p>1.6. Настоящая Политика действует только на территории Российской Федерации.</p>
      <p>1.7. Мы вправе вносить в настоящую Политику изменения по своему усмотрению, в том числе,
        когда соответствующие изменения связаны с изменениями в законодательстве или в работе
        Сайта. Вам следует самостоятельно контролировать наличие изменений в настоящей Политике.
        Новая реакции Политики вступает в силу с момента ее размещения, если иное не предусмотрено
        новой редакцией Политики.</p>
      <p>1.8. Используя Сайт и оставляя данные о себе, вы соглашаетесь со всеми условиями
        настоящей Политики и предоставляете Компании согласие на обработку, включая сбор, запись,
        систематизацию, накопление, хранение, уточнение (обновление, изменение), сопоставление,
        извлечение, использование, обезличивание, блокирование, удаление, автоматическую обработку
        и уничтожение вашей персональной информации, включая результаты их автоматизированной
        обработки, передачу персональной информации третьим лицам, а также на сбор (получение)
        Компанией Персональных данных от третьих лиц и совместную обработку вашей персональной
        информации согласно настоящей Политике.</p>
      <p>1.9. Используя Сайт и оставляя данные о себе, вы предоставляете согласие Компании на
        получение от нас информационных сообщений на ваш адрес электронной почты, на ваши
        аккаунты в мессенджерах Telegram, WhatsApp, Viber и (или) абонентский номер телефона,
        указанный вами при работе с Сайтом. Используя Сайт, вы также в соответствии с ч. 1 ст. 18
        Федерального закона "О рекламе" даете свое согласие Компании на получение сообщений
        рекламного характера на ваш адрес электронной почты, на ваши аккаунты в мессенджерах
        Telegram, WhatsApp, Viber и (или) абонентский номер телефона, указанный вами при работе с
        Сайтом.</p>
      <p>1.10. Присоединение к настоящей Политике и согласие с ее условиями может быть выражено
        вами, в том числе, посредством ввода какой-либо персональной информации в формах,
        размещенных на Сайте, а также путем проставления соответствующего значения (флажка,
        галочки) в чекбоксах в соответствующих формах Сайта, в частности, при оформлении заказа, при
        подписке на новости, при активации подарочной картой и пр.</p>

      <h2 class="h4 my-8">2. В настоящей Политике используются следующие термины:</h2>
      <p>"Компания" (также "Мы") - Общество с ограниченной ответственностью "Сладкие подарки"
        (ИНН 7801279117, ОГРН 1157847149965), расположенное по адресу: 191144, Санкт-Петербург,
        Новгородская ул., дом 12, лит. Б, пом. 2-Н.</p>
      <p>"Персональные данные" - информация, переданная пользователем Компании. Категории
        собираемых и обрабатываемых нами персональных данных поименованы в п. 4 настоящей
        Политики.</p>
      <p>"Пользователь" (также "Вы") - любое лицо, использующее Сайт.</p>
      <p>"Сайт" -
        <router-link to="/">schastyesweet.com</router-link>
      </p>
      <p>"Телефон" - <a href="tel:+79217881818">+7(921)788-18-18</a>.</p>
      <p>"Электронная почта" - <a href="mailto:home@schastye.com">home@schastye.com</a></p>
      <p>"Обработка персональных данных" - сбор, запись, систематизация, накопление, хранение,
        уточнение (обновление, изменение), сопоставление, извлечение, использование, обезличивание,
        блокирование, удаление, автоматическая обработка и уничтожение вашей персональной
        информации, включая результаты их автоматизированной обработки, передачу персональной
        информации третьим лицам, а также на сбор (получение) Компанией Персональных данных от
        третьих лиц и совместная обработку вашей персональной информации согласно настоящей
        Политике.</p>

      <h2 class="h4 my-8">3. Кто выступает в качестве оператора Ваших персональных данных?</h2>
      <p>Оператором Ваших персональных данных, то есть, лицом, которое занимается их обработкой и
        обеспечивает их защиту в соответствии с настоящей Политикой, является Компания - Общество с
        ограниченной ответственностью "Сладкие подарки" (ИНН 7801279117, ОГРН 1157847149965),
        расположенное по адресу: 191144, Санкт-Петербург, Новгородская ул., дом 12, лит. Б, пом. 2-Н.
        Обрабатывать Ваши персональные данные могут также другие лица, которым мы поручим такую
        обработку в соответствии с настоящей Политикой.</p>


      <h2 class="h4 my-8">4. Какие персональные данные мы обрабатываем?</h2>
      <p>4.1. В зависимости от целей мы можем обрабатывать, в том числе, следующие виды данных:</p>
      &lt;!&ndash;&ndash;&gt;
      <div class="policy__table">
        <h3 class="h5 mt-4">контактные и личные данные</h3>
        <p class="mt-3">полное имя, дата рождения, псевдоним (никнейм), пол, язык, город
          пребывания, адрес доставки, адрес электронной почты, номер телефона,
          логин в Telegram, логин в Skype, аккаунт в Whatsapp, Viber</p>
        <h3 class="h5 mt-4">финансовая информация</h3>
        <p class="mt-3">данные банковского счета, если мы их запросим (например, для
          осуществления платежа или возврата денег), информация о покупках,
          заказах, возвратах</p>
        <h3 class="h5 mt-4">удостоверение личности</h3>
        <p class="mt-3">копии документов, удостоверяющих личность, таких как паспорт,
          временное удостоверение личности гражданина - только по нашему
          запросу (например, для возврата товара и (или) денег)</p>
        <h3 class="h5 mt-4">дополнительная информация, в т.ч., собираемая автоматически</h3>
        <p class="mt-4">ссылки на ваш аккаунт Google; аккаунты на facebook.com, google.com,
          vk.com, ok.ru; сведения о том, открываете ли Вы наши электронные письма
          и переходите ли по ссылкам в них; IP-адрес; информация из cookie файлов;
          информация об используемом Вами браузере</p>

      </div>
      <p class="mt-4">4.2. Мы не проверяем достоверность персональной информации, предоставляемой Вами.
        Однако мы исходим из того, что Вы предоставляете достоверную и достаточную персональную
        информацию, и поддерживаете эту информацию в актуальном состоянии.</p>

      <h2 class="h4 my-8">5. Как мы получаем Ваши персональные данные?</h2>
      <p>5.1. Вы предоставляете нам свои персональные данные, когда заполняете некоторые формы
        на Сайте, или общаетесь с нами, например, по электронной почте, телефону, в мессенджерах или
        в социальных сетях. Когда Вы пишете или звоните нам, мы ведём запись таких сообщений.</p>
      <p>5.2. Некоторые сервисы, представленные на Сайте, доступны только при условии
        предоставления Вами персональных данных путём заполнения форм, при этом определенные
        поля будут помечены в качестве обязательных для заполнения: эта информация нужна нам для
        того, чтобы оказать Вам требуемую услугу или обеспечить доступ к определенным сервисам и
        функциям. Если Вы решите не сообщать нам указанные данные, Вам может быть отказано в
        использовании соответствующих сервисов или функций Сайта.</p>
      <p>5.3. В определенных случаях третье лицом может предоставить нам информацию о вас,
        используя какую-либо функцию или сервис на Сайте, например, направив Вам подарочную карту
        или отправив заказ на ваш адрес. В этих случаях мы обрабатываем ваши данные лишь в той степени, в которой они
        имеют отношение к такой функции или услуге, как указано в настоящей
        Политике. Мы можем получать Ваши данные от третьих лиц минуя сервисы, представленные на
        Сайте. Такие данные позволяют нам обновлять, расширять и анализировать имеющиеся у нас
        данные; предотвращать или выявлять случаи мошенничества; обрабатывать платежи;
        анализировать, как Вы используете Сайт и представленные на нем сервисы.</p>
      <p>5.4. Мы вправе собирать данные автоматически, когда Вы посещаете и используете Сайт,
        используя такие инструменты, как файлы cookie в браузере и прочие аналогичные технологии. Эта
        информация может включать сведения о компьютере или мобильном устройстве и их
        использовании. Примеры такой информации: информация об устройстве, протокол IP, тип и
        версия браузера, операционная система, языковые настройки, информация о мобильной сети,
        операционная система мобильного телефона, тип и версия браузера мобильного устройства,
        текущее местоположение, ссылки в формате URL, информация об использовании: например,
        время входа на Сайт и выхода, какие страницы Вы посещаете и какие функции используете.</p>
      <p>5.5. Файлы cookie не используются нами для обработки Ваших персональных данных и не
        направлены на установление Вашей личности. Вся информация, которая собирается и
        анализируется таким образом, обезличена и анонимна. Использование технологии cookie
        представляет собой размещение на Сайте определенного набора символов (знаков), которые
        сохраняются на Вашем устройстве при доступе к определенным страницам
        информация используется нами в следующих целях:</p>
      <ol class="ul-letters my-4">
        <li>
          <p>аналитическая деятельность по исследованию предпочтений посетителей Сайта;</p>
        </li>
        <li>
          <p>ведение статистики по активности посетителей Сайта;</p>
        </li>
        <li>
          <p>повышение производительности и эффективности Сайта;</p>
        </li>
        <li>
          <p>аналитика и исправление ошибок Сайта, улучшение работы всех ресурсов Сайта;</p>
        </li>
        <li>
          <p>защита сервисов Сайта от мошенничества и иных недобросовестных действий;</p>
        </li>
        <li>
          <p>показ рекламных объявлений наших сервисов на сторонних сайтах.</p>
        </li>
      </ol>
      <p>5.6. На Сайте также используется следующие программы для автоматического сбора данных:</p>
      <ol class="ul-letters my-4">
        <li>
          <p>сервис Яндекс. Метрика (программа, принадлежащая ООО "ЯНДЕКС"). С ее помощью
            осуществляется сбор анонимных данных о посещениях сайтов (без привязки к персональным
            данным посетителей сайта) с целью лучшего понимания поведения и улучшения сайтов:</p>
          <ul class="ml-8 my-4">
            <li>
              <p>электронные данные (HTTP-заголовки, IP-адрес, файлы cookie, веб-маяки/пиксельные теги,</p>
            </li>
            <li>
              <p>данные об идентификаторе браузера, информация об аппаратном и программном обеспечении);</p>
            </li>
            <li>
              <p>дата и время осуществления доступа к сайтам и/или сервисам Яндекс;</p>
            </li>
            <li>
              <p>информация о Вашей активности во время использования сайтов и/или сервисов Яндекс;</p>
            </li>
            <li>
              <p>Для получения дополнительных сведений о сборе данных сервисом "Яндекс.Метрика"
                ознакомьтесь с документом "Условия использования сервиса Яндекс.Метрика" по адресу
                <a href="http://legal.yandex.ru/metrica_termsofuse/" target="_blank">http://legal.yandex.ru/metrica_termsofuse/</a>,
                а также "Политикой конфиденциальности персональной
                информации" этой компании по адресу: <a href="http://legal.yandex.ru/confidential/" target="_blank">http://legal.yandex.ru/confidential/</a>.
              </p>
            </li>
          </ul>
        </li>
        <li>
          <p>Сервис Google Analytics. На Сайте используется Google Analytics - это программа для
            отслеживания и веб-аналитики, разработанная Google, Inc. С ее помощью осуществляется сбор
            анонимных данных о посещениях сайтов (без привязки к персональным данным посетителей
            сайта):</p>
          <ul class="ml-8 my-4">
            <li>
              <p>информация о приложениях, браузерах и устройствах, которые Вы используете для
                доступа к сервисам Google. Это обеспечивает работу таких функций, как автоматическое
                обновление приложений и затемнение экрана при малом заряде батареи.</p>
            </li>
            <li>
              <p>уникальные идентификаторы, а также такие данные, как тип и настройки браузера и
                устройства, операционная система, мобильная сеть (включая название оператора и номер
                телефона) и номер версии приложения. Информация о взаимодействии Ваших приложений,
                браузеров и устройств с сервисами Google, в том числе IP-адрес, отчеты о сбоях, сведения о
                действиях в системе, дата и время, когда Вы посетили ресурс Google, и URL, с которого Вы на него
                перешли (URL перехода).</p>
            </li>
            <li>
              <p>Для получения дополнительных сведений о сборе данных сервисом Google, пожалуйста,
                ознакомьтесь с документом "Политикой конфиденциальности. Условия использования" этой
                компании по адресу: <a href="https://policies.google.com/privacy?hl=ru" target="_blank">https://policies.google.com/privacy?hl=ru</a>.
              </p>
            </li>
          </ul>
        </li>
      </ol>

      <h2 class="h4 my-8">6. С какой целью мы собираем и обрабатываем Ваши персональные данные?</h2>
      <p>6.1. В зависимости от того каким образом Вы используете Сайт, и по каким каналам связи
        происходит наше с вами взаимодействие, Ваши персональные данные используются нами для
        следующих целей:</p>
      <div class="policy__table">
        <p class="h4 mt-4 color-golden d-none d-md-block">Цель</p>
        <p class="h4 color-golden d-none d-md-block">Подробнее</p>
        <h3 class="h5 mt-4">В целях заключения, изменения, исполнения и расторжения договора купли- продажи товаров и
          (или) договора оказания услуг</h3>
        <div class="mt-3">
          <p>Данный пункт включает в себя обработку Ваших персональных данных, в том числе, в следующих целях:</p>
          <ol class="ul-letters my-2">
            <li>
              <p>Для того, чтобы обеспечить Вам возможность оплатить и
                получить товар, приобретенный на Сайте либо по иному каналу
                связи (Телефон / Электронная почта);</p>
            </li>
            <li>
              <p>Для того, чтобы предоставить Вам возможность изменить
                заказ, сделанный на Сайте либо по иному каналу связи (Телефон /
                Электронная почта), если данная опция имеется в отношении такого
                заказа;</p>
            </li>
            <li>
              <p>Для направления Вам сервисных сообщений о статусе
                вашего заказа;</p>
            </li>
            <li>
              <p>Для того, чтобы обеспечить нам возможность передать Вам
                заказ, сделанный на Сайте либо по иному каналу связи (Телефон /
                Электронная почта);</p>
            </li>
            <li>
              <p>Для отправления Вам обновлений и уведомлений
                касательно опций, товаров и услуг, являющихся предметом
                договора розничной купли-продажи дистанционным способом, в том
                числе для проведения опросов в отношении качества наших
                сервисов, в целях выявления степени Вашей удовлетворенности
                оказанными услугами.;</p>
            </li>
            <li>
              <p>Для администрирования оплаты приобретаемых вами
                товаров и услуг, вне зависимости от выбранного способа оплаты;
                Для выполнения наших обязательств по заключённому с
                вами договору и оказание Вам помощи в исполнении договора;
                Для обеспечения соблюдения наших условий, правил и
                принципов в отношении реализуемых нами товаров и услуг;</p>
            </li>
            <li>
              <p>Для активации механизмов, необходимых для
                предупреждения и обнаружения случаев несанкционированного
                использования Сайта, например, во время покупки и возврата
                товаров, а также возможных мошеннических действий,
                совершаемых против Вас и/или против нас. Если мы считаем, что
                транзакция и (или) заказ являются мошенническими, или если мы
                обнаруживаем ненормальное поведение, указывающее на попытку
                мошеннического использования наших сервисов, продуктов, Сайта
                или услуг, такая обработка может привести к таким последствиям,
                как блокировка транзакции или аннулирование вашего заказа.</p>
            </li>
            <li>
              <p>Для предотвращения причинение вреда и самозащиты
                наших прав;</p>
            </li>
            <li>
              <p>Для разрешения споров в судебном порядке;</p>
            </li>
            <li>
              <p>Для обработки возможных обменов или возвратов товаров
                после их приобретения, а также для управления запросами о
                наличии товаров и бронирования товаров через Сайт (по Телефону,
                Электронной почте);</p>
            </li>
            <li>
              <p>В целях выставления счетов и предоставления чеков и
                счетов на покупки, совершенные посредством Сайта;</p>
            </li>
            <li>
              <p>Для обеспечения возможности использования других
                имеющихся функций или услуг, таких как покупка, активация,
                управление и использование подарочной карты;</p>
            </li>
            <li>
              <p>Для обеспечения возможности предлагать Вам те или иные
                товары через Сайт (например, поиск товаров и магазинов, в которых
                эти товары доступны, резервирование товаров и т. п.).</p>
            </li>
          </ol>
        </div>
        <h3 class="h5 mt-4">Для предоставления
          ответов на запросы и заявки, осуществляемые посредством каналов обслуживания</h3>
        <div class="mt-3">
          <p>Данный пункт включает в себя обработку Ваших персональных данных, в том числе, в следующих целях:</p>
          <ol class="ul-letters my-4">
            <li>
              <p>Для ответов на Ваши запросы и заявки, получаемые нами по
                любым каналам связи. Мы обрабатываем только те персональные
                данные, которые строго необходимы для работы с вашими заявками
                или для ответов на вопросы, изложенные в них.
                Если в качестве канала обслуживания клиентов Вы выбираете чат в
                социальных сетях и у других посредников (если он доступен), из
                социальной сети или аккаунта у посредника будут импортироваться
                некоторые ваши персональные данные, такие как название
                аккаунта, фотография в вашем аккаунте, номер телефона или имя
                пользователя.</p>
            </li>
            <li>
              <p>Для исполнения наших обязательств, в т.ч.:</p>
              <ul class="ul">
                <li>
                  <p>для предоставления ответов в ходе правовых, регуляторных,
                    административно-правовых и уголовно-правовых процедур;</p>
                </li>
                <li>
                  <p>для предоставления ответов на запросы о предоставлении
                    информации, которые поступают от органов государственной власти
                    и местного самоуправления или других третьих лиц;</p>
                </li>
              </ul>
            </li>
          </ol>
        </div>
        <h3 class="h5 mt-4">В маркетинговых целях</h3>
        <div class="mt-3">
          <p>Данный пункт включает в себя обработку Ваших персональных
            данных, в том числе, в следующих целях:</p>
          <ol class="ul-letters my-4">
            <li>
              <p>Для индивидуализации предложения услуг и товаров, а
                также в целях получения возможности давать Вам рекомендации на
                основе вашего взаимодействия с нами на Сайте и анализа вашего
                профиля пользователя (например, на основе Вашей истории
                покупок и просмотров в браузере).</p>
            </li>
            <li>
              <p>Для администрирования Ваших подписок на наши новости и
                рекламу, в том числе для отправки Вам персонализированной
                информации и рекламных сообщений о наших товарах и услугах при
                помощи различных средств связи (таких как электронная почта,
                WhatsApp, Telegram и СМС-сообщения). Мы также можем
                предоставлять Вам эту информацию посредством push-
                уведомлений в случае, если они активированы на вашем
                устройстве. Вы можете отписаться от рассылки в любое время без
                каких-либо дополнительных расходов. Это можно сделать в
                соответствующем разделе письма, направленного по электронной
                почте (кнопка "отписаться от рассылки"), либо путем направления
                нам соответствующего уведомления по Электронной почте с
                указанием Вашего имени и иных Ваших идентификаторов, которые
                позволят нам определить рассылку, подлежащую отмене;</p>
            </li>
            <li>
              <p>Для выявления Ваших предпочтений и учета их при отправке
                Вам информации относительно того, какие товары и услуги в
                наибольшей степени соответствуют вашим запросам. Например,
                исходя из Вашей истории покупок и просмотров, мы будем
                отправлять Вам рекомендации товаров, которые, с нашей точки
                зрения, могут быть Вам интересны.</p>
            </li>
            <li>
              <p>Для показа Вам рекламных объявлений в Интернете,
                которые Вы можете видеть при посещении веб-сайтов и
                приложений, например, в социальных сетях. Рекламные
                объявления, которые Вы видите, могут быть случайными, но в
                некоторых случаях они могут быть связаны с вашими
                предпочтениями или историей покупок и просмотров в браузере.</p>
            </li>
            <li>
              <p>Для проведения расчетов и сегментного анализа по
                рекламным объявлениям, которые мы показываем пользователям
                на сторонних платформах.</p>
            </li>
            <li>
              <p>Для проведения промоакций (например, для организации
                конкурсов, розыгрышей или для отправки Вам сообщений об их
                проведении).</p>
            </li>
          </ol>
        </div>
        <h3 class="h5 mt-4">В технических целях</h3>
        <div class="mt-3">
          <p>Данный пункт включает в себя обработку Ваших персональных
            данных, в том числе, в следующих целях:</p>
          <ol class="ul-letters my-4">
            <li>
              <p>Для сбора аналитической информации, которая позволит
                нам улучшить качество Сайта и предоставляемых нами сервисов;
                Для обнаружения, предотвращения и решения технических
                проблем Сайта;</p>
            </li>
            <li>
              <p>Для управления Сайтом в контексте внутренней
                деятельности, включая цели поиска и устранения неисправностей,
                анализа данных, тестирования, исследования, статистики и
                опросов;</p>
            </li>
            <li>
              <p>Для предоставления содержимого сайта наиболее
                эффективным образом;</p>
            </li>
            <li>
              <p>Для поддержания безопасности и надёжности Платформы.</p>
            </li>
          </ol>
        </div>
      </div>

      <h2 class="h4 my-8">7. Как долго мы храним ваши персональные данные?</h2>
      <p>7.1. Срок хранения Ваших персональных данных зависит от целей их обработки:</p>
      <div class="policy__table">
        <p class="h4 mt-4 color-golden d-none d-md-block">Цель</p>
        <p class="h4 color-golden d-none d-md-block">Срок хранения персональных данных</p>
        <h3 class="h5 mt-4">заключение, изменение, исполнение и расторжение договора купли-продажи товаров и (или) договора оказания услуг</h3>
        <p class="mt-3">Мы обрабатываем ваши данные в течение 7 лет со дня осуществления вами последнего заказа товара (услуги). Такой срок необходим нам для заключения и исполнения договора купли- продажи товаров или оказания услуг, в том числе потенциального возврата товаров, а также обработки предъявляемых вами жалоб или претензий, связанных с приобретением указанных товаров или услуг, с учетом требований законодательства и органов власти в части хранения отчетной и бухгалтерской информации.</p>
        <h3 class="h5 mt-4"></h3>
        <p class="mt-3"></p>
        <h3 class="h5 mt-4">Предоставление ответов на запросы и заявки, осуществляемые посредством каналов обслуживания</h3>
        <p class="mt-3">В течение 1 года со дня получения (направления) последнего сообщения в рамках переписки. В течение 3 лет со дня предоставления ответа на запрос органа власти, если в течение срока хранения Ваших персональных данных они были запрошены таким органом.</p>
        <h3 class="h5 mt-4">Маркетинг</h3>
        <p class="mt-3">В разрезе рекламных и информационных рассылок мы обрабатываем ваши персональные данные до тех пор, пока Вы не откажетесь от подписки на рассылку. Аналогичным образом, мы будем показывать Вам рекламные объявления с учетом Ваших индивидуальных интересов до тех пор, пока Вы не измените настройки вашего устройства, браузера или файлов cookie таким образом, чтобы отозвать соответствующее разрешение. Если Вы участвуете в промоакциях, мы сохраняем ваши данные в течение 6 месяцев с момента завершения акции и 5 лет, если по результатам промоакции вы были признаны победителем с предоставлением вам приза / подарка / иного вознаграждения.</p>
        <h3 class="h5 mt-4">Технические цели</h3>
        <p class="mt-3">Мы обрабатываем ваши данные по мере необходимости в течение срока, необходимого нам для выполнения той или иной процедуры по устранению неполадок или проведения исследования в целях обеспечения качества.</p>
      </div>
      <p class="mt-4">7.2. Обработка Ваших персональных данных осуществляется строго в течение срока, необходимого для достижения требуемой цели. В дальнейшем мы обеспечиваем их надлежащее хранение и защиту на время, в течение которого может возникнуть обязательство по предоставлению их в органы государственной (муниципальной) власти в связи с предъявлениями к нам каких-либо требований, претензий, жалоб и исков. Мы удаляем ваши персональные данные по истечении предусмотренного законом срока давности предъявления к нам указанных выше требований и (или) потенциального привлечения нас к ответственности.</p>

      <h2 class="h4 my-8">8. Передаем ли мы ваши персональные данные третьим лицам?</h2>
      <p>8.1. Для достижения целей, указанных в настоящей Политике, мы должны обеспечить доступ к
        вашим персональным данным третьим лицам, обеспечивающим предоставление нам услуг и
        продажу товаров, прямо либо косвенно связанных с работой Сайта и иных предоставляемых нами
        сервисов, а также в целях обеспечения возможности исполнения нами обязательств по
        заключенному с вами договору купли-продажи товаров дистанционным способом и в целях
        обеспечения возможности исполнения вами обязательств по указанному договору.</p>
      <p>8.2. Принимая настоящую Политику, Вы соглашаетесь с тем, что мы вправе раскрывать
        информацию о Вас только:</p>
      <ol class="ul-letters my-4">
        <li>
          <p>По требованию законодательства.</p>
          <p>Мы вправе раскрывать информацию о Вас в тех случаях, когда обязаны это сделать в
            соответствии с законодательством, запросами государственных органов, судебным
            разбирательством, постановлением суда или судебным процессом. Например, мы вправе
            раскрыть информацию в ответ на постановление суда, повестку в суд, запрос органов
            государственной власти. Мы также вправе раскрывать информацию о вас, когда проводим
            расследование или сотрудничаем в расследовании мошенничества или другой незаконной
            деятельности с целью предотвращения и обнаружения мошенничества или другого преступления;</p>
        </li>
        <li class="mt-4">
          <p>С тем, чтобы позволить третьим лицам оказывать услуги.</p>
          <p>Мы вправе передавать ваши данные деловым партнерам, поставщикам услуг,
            подрядчикам или агентам, предоставляющим услуги нам или от нашего имени и требующим
            доступа к такой информации с целью выполнения обязательств. Примеры таких услуг третьих лиц:
            обработка платежей, анализ данных, доставка электронной почты, хостинг, обслуживание
            клиентов, организация информационных рассылок, предоставление нам доступа к базам данных
            по борьбе с мошенничеством, обеспечение безопасности Сайта и юридической защищенности
            Компании.</p>
          <p>Мы вправе разрешить отдельным третьим лицам использовать на Сайте технологию
            отслеживания, что позволит им собирать данные о том, как Вы взаимодействуете с Сайтом с
            течением времени. Мы также вправе делиться информацией о Вас с нашими аффилированными
            лицами. К аффилированным лица относятся наши материнские компании и любые дочерние
            компании, партнёры по совместным предприятиям или другие члены нашей группы компаний.
            Наши аффилированные лица, партнёры и подрядчики также должны будут соответствовать нашим
            стандартам в части обработки данных и безопасности. Ваши данные могут быть переданы
            следующим организациями, осуществляющим деятельность с использованием товарных знаков и
            знаков обслуживания, используемых нами (Счастье, Le Bonheue, Le Maison du Bonheur, L’Art de la
            Maison): ООО "Счастье у Исаакия", ИНН 7838037033; ООО "Счастье на Рубинштейна", ИНН
            7804208643; ООО "Счастье на Петроградке", ИНН 7813219779; ООО "Суперфуды", ИНН
            7841056727; ООО "Счастье на Крыше", ИНН 7806509195; ООО "Лавка Счастья", ИНН
            7801279854; ООО "Фабрика Счастья", ИНН 7813228685; ООО "Сладкие Подарки", ИНН
            7801279117; ООО "Сэвен Энджелс", ИНН 7813223856</p>
        </li>
        <li class="mt-4">
          <p>При передаче бизнеса и в рамках корпоративных реорганизационных процедур. Мы вправе
            раскрывать или передавать ваши данные в связи с любым слиянием, продажей активов компании,
            финансировании или приобретении всего или части нашего бизнеса другой компанией, или во
            время переговоров о чём-либо из перечисленного. Мы обязуемся уведомить Вас до того, как ваши
            личные данные будут переданы и станут предметом другой политики конфиденциальности;</p>
        </li>
      </ol>

      <h2 class="h4 my-8">9. Какие права у Вас есть в отношении обрабатываемых нами персональных данных?</h2>
      <div class="policy__table &#45;&#45;3">

        <p class="h4 mt-4 color-golden d-none d-md-block">Право</p>
        <p class="h4 color-golden d-none d-md-block">Правомочия</p>
        <p class="h4 color-golden d-none d-md-block">Как воспользоваться правом</p>

        <h3 class="h5 mt-4">доступ к Персональным данным</h3>
        <p class="mt-3">получить подтверждение, если мы обрабатываем ваши Персональные данные; получить доступ к своим Персональным данным и информацию о том, как мы используем ваши Персональные данные.</p>
        <p class="mt-3">пожалуйста, ознакомьтесь с настоящей Политикой или свяжитесь с нами по адресу Электронной почты.</p>

        <h3 class="h5 mt-4">исправить неточные данные</h3>
        <p class="mt-3">потребовать исправления любых неполных или неточных Персональных данных о Вас.</p>
        <p class="mt-3">пожалуйста, свяжитесь с нами по адресу Электронной почты.</p>

        <h3 class="h5 mt-4">Возражать против обработки</h3>
        <p class="mt-3">возражать против обработки Ваших Персональных данных, если мы обрабатываем их в собственных законных интересах.</p>
        <p class="mt-3">пожалуйста, свяжитесь с нами по адресу Электронной почты.</p>

        <h3 class="h5 mt-4">Отозвать согласие на обработку данных</h3>
        <p class="mt-3">отозвать своё согласие на обработку Ваших Персональных данных.</p>
        <p class="mt-3">пожалуйста, свяжитесь с нами по адресу Электронной почты.</p>

        <h3 class="h5 mt-4">Отозвать согласие на электронный маркетинг</h3>
        <p class="mt-3">отказаться от получения рекламной рассылки.</p>
        <p class="mt-3">пожалуйста, свяжитесь с нами по адресу Электронной почты либо следуйте инструкции, приведенной в рекламном сообщении для осуществления отписки от рассылки.</p>

        <h3 class="h5 mt-4">подавать жалобы</h3>
        <p class="mt-3">пожаловаться в орган по надзору за соблюдением законодательства о защите персональных данных на сбор и использование нами Ваших Персональных данных.</p>
        <p class="mt-3">пожалуйста, свяжитесь с надзорным ведомством: <a href="https://rkn.gov.ru" target="_blank">rkn.gov.ru</a></p>

      </div>

      <h2 class="h4 my-8">10. Как мы обеспечиваем защиту Ваших персональных данных?</h2>
      <p>10.1. Мы принимаем необходимые и достаточные организационные и технические меры для
        защиты Персональных данных от неправомерного или случайного доступа, уничтожения,
        изменения, блокирования, копирования, распространения, а также от иных неправомерных
        действий с ней третьих лиц.</p>
      <p>10.2. Мы устанавливаем следующие меры правового, организационного и правового характера
        для защиты Персональной информации: назначено ответственное лицо по организации обработки
        персональных данных; изданы и внедрены документы, определяющие политику общества в
        отношении обработки персональных данных, локальные акты, устанавливающие процедуры
        направленные на предотвращение и выявление нарушений законодательства РФ, устранение
        последствий таких нарушений; работники общества непосредственно осуществляющие обработку
        персональных данных, ознакомлены с положениями законодательства РФ "О персональных
        данных", в том числе требованиями к защите персональных данных, документами определяющими
        политику общества в отношении обработки персональных данных, локальными актами по
        вопросам обработки персональных данных.</p>
      <p>10.3. Мы храним вашу персональную информацию, обеспечивая ее конфиденциальность и
        защиту от неправомерного или случайного доступа к ним третьих лиц.</p>
      <p>10.4. Мы обязуемся предотвращать попытки несанкционированного доступа к вашим
        персональным данным; своевременно обнаруживать и пресекать такие попытки.</p>
      <p>10.5. Мы вправе произвести блокировку, исключение, удаление информации, размещенной без
        согласия пользователя, если такая информация не отвечает требованиям законодательства и
        положениям настоящей Политики.</p>
      <p>10.6 Мы просим Вам не забывать, что мы не можем обеспечить или гарантировать полную
        безопасность Ваших данных, которые Вы передаете на Сайте или по иным каналам связи.
        Несмотря на то, что мы сделаем все возможное для защиты Ваших Персональных данных,
        передача Персональных данных на Сайт и по иным каналам связи осуществляется на ваш страх и
        риск. Пожалуйста, пользуетесь Сайтом только в безопасной среде: например, используйте
        средства сетевой защиты, выбирайте провайдера, который шифрует трафик, и т.д. После
        получения персональных данных мы следуем строгим правилам и используем средства защиты с
        целью предотвратить несанкционированный доступ. Тем не менее, мы не несём ответственности
        ни за какой ущерб, связанный с несанкционированным доступом к Персональным данным.</p>-->

    </v-container>
  </section>
</template>

<script>
export default {
  name: "policy",
  computed: {
    text() {
      return this.$store.state.page.PageController?.page?.DETAIL_TEXT;
    }
  }
}
</script>

<style lang="scss">
.policy {
  width: 100%;

  a {
    overflow-wrap: break-word;
  }

  &__wrapper {
    width: 100%;
  }

  &__list {
    list-style-type: decimal;
  }

  &__text {
    font-size: 15px;
    line-height: 28px;
    letter-spacing: 2px;
  }

  h5, .policy__text {
    color: $black-200;
  }

  &__table {
    @include up($md) {
      display: grid;
      grid-template-columns: 30% 70%;
      gap: 0 15px;

      &.--3 {
        grid-template-columns: 24% 38% 38%;
      }
    }
  }
}
</style>